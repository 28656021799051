.my-card-container {
    width: 100%;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
    margin-bottom: 24px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.my-card-content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
}

.my-card-title {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 16px;
}

.my-card-dropdown {
    opacity: 0.5;
    color: #000000;
    font-size: 12px;
    font-weight: 400;
}