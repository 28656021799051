
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');


* {
    /* font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif; */
    font-family: 'Open Sans', sans-serif;
    
}
:where(.css-dev-only-do-not-override-xu9wm8).ant-modal .ant-modal-content  {
    margin-top:  -78px;
}